import {cloneElement, useRef, useCallback, useState} from 'react';

import useIntersectionObserver from '@/hooks/useIntersectionObserver';
interface InlineVideoIOWrapperProps extends React.HTMLAttributes<HTMLElement> {}

export default function InlineVideoIOWrapper({
  children,
  ...props
}: InlineVideoIOWrapperProps) {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [isInView, setIsInView] = useState<Boolean>(false);

  const playObserverCallBack = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry: IntersectionObserverEntry) => {
        setIsInView(entry.isIntersecting);
      });
    },
    [setIsInView],
  );

  useIntersectionObserver(wrapperRef, playObserverCallBack, {
    threshold: 0,
    rootMargin: '0px',
  });

  return (
    <div ref={wrapperRef} className="h-full">
      {cloneElement(children as any, {
        isInView,
        ...props,
      })}
    </div>
  );
}
